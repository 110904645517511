import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import "./i18n";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import { reducers } from "./store/rootReducer";
import { rootSaga } from "./store/rootEffect";
import AppAction from "./store/app/appAction";
import config from "./config/config";

const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === AppAction.RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action)
}
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

const isEmbed = (config.irbisProMode && document.referrer && window !== window.parent) || !config.irbisProMode;

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      {
        isEmbed && <App />
      }
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)
