import {SupportService} from "../../services/support";
import moment from "moment";
import {imageByIndex} from "../../helpers/imageByIndex";
import {getCoordsByIso} from "../../services/countries";
import i18n from "i18next";
import {Tag} from "antd";
import SocialIcon from "../../components/UI/Icons/SocialIcon";
import {targetLink} from "../../helpers/targetLink";
import config from "../../config/config";

export const transformWebintTargets = (targets = []) => {
  const out = [...targets];
  out.forEach(target => {
    //console.log('target', target);
    if (target.imageIds && target.imageIds?.filter(id => id !== "-1")?.length > 0) {
      const [image] = target.imageIds;
      target.avatar = imageByIndex(image);
    } else {
      target.avatar = target?.profileImageUrl;
    }

    target.name = target.profileName;
    if (target.socialNetwork === "TELEGRAM") {
      if (!target.name && (target?.firstNameT || target?.lastNameT )) {
        target.name = [target?.firstNameT, target?.lastNameT].join(' ');
      }
      if (!target.name && target?.username) {
        target.name = target.username;
      }
      if (!target.name && target?.profileId) {
        target.name = target.profileId;
      }
    }

    target.profiles = [{sn: target.socialNetwork, name: target.name}];
  })
  console.log('transformed Targets', out);
  return out;
};

export const transformWebintSearchProgress = (searchProgress = []) => {
  //search results
  const performedSearchProgress = [...searchProgress];

  performedSearchProgress.forEach(res => {
    res.time = (res.updateDate && res.createdDate)
      ? SupportService.durationTime(moment(res.updateDate).diff(moment(res.createdDate), 'seconds'))
      : ' - ';

    if (!res?.socialNetwork
      && [
        'googlesearch_api_search_spider',
        'googlesearch_search_api_spider',
        'keyword_spider'
      ].includes(res?.serviceName)) {
      res.socialNetwork = 'GOOGLE'
    }
    if (!res?.socialNetwork && res?.serviceName) {
      res.socialNetwork = res?.serviceName?.toUpperCase();
    }

  });

  return performedSearchProgress;
}

export const transformWebintTrends = (apiTrends = []) => {
  const trends = apiTrends?.default?.geoMapData || [];

  const performedTrends = {
    markers: [...trends],
  };

  performedTrends.markers.forEach(res => {
    const coords = getCoordsByIso(res.geoCode);
    if (coords) {
      res.lat = coords.lat * 1;
      res.lng = coords.lng * 1;
    }
  });

  const lats = performedTrends.markers.filter(({lat}) => lat).map(({lat}) => lat);
  const lngs = performedTrends.markers.filter(({lng}) => lng).map(({lng}) => lng);

  performedTrends.minLat = Math.min(...lats);
  performedTrends.maxLat = Math.max(...lats);
  performedTrends.minLng = Math.min(...lngs);
  performedTrends.maxLng = Math.max(...lngs);

  return performedTrends;
}

export const transformWebintTrendsSerpAPI = (apiTrends) => {
  console.log('apiTendsSerp', apiTrends);
  let trends = [];
  if (apiTrends?.interest_by_region) {
    trends = apiTrends?.interest_by_region;
  }

  const performedTrends = {
    markers: [...trends],
  };

  performedTrends.markers.forEach(res => {
    const coords = getCoordsByIso(res.geo);
    if (coords) {
      res.lat = coords.lat * 1;
      res.lng = coords.lng * 1;
    }
    res.geoName = res.location;
    res.value = [res.extracted_value];
  });

  const lats = performedTrends.markers.filter(({lat}) => lat).map(({lat}) => lat);
  const lngs = performedTrends.markers.filter(({lng}) => lng).map(({lng}) => lng);

  performedTrends.minLat = Math.min(...lats);
  performedTrends.maxLat = Math.max(...lats);
  performedTrends.minLng = Math.min(...lngs);
  performedTrends.maxLng = Math.max(...lngs);

  return performedTrends;
}

export const prepareFetchTargetRequest = (target = {}) => {
  const formData = new FormData();

  const items = [];
  const item = {};

  //BY SOCIAL NETWORK

  item.socialProfiles = [];
  item.socialProfiles.push({
    profileId: target.profileId,
    socialNetwork: target.socialNetwork,
    avatarId: target?.avatarId
  })

  items.push(item);
  formData.append("data", JSON.stringify({items}));

  return formData;
}

export function prepareUpdateWebintForm(apiTask = {}){
  const formValues = {};

  /*taskName: null,
    taskType: 'groups',
    images: [],
    avatars: [],
    monitoring: false,
    monitoringStart: null,
    monitoringEnd: null,
    collectPeriod: '1H',
    collectLikes: false,
    socialNetwork: {sid: 'FB'},

    //groups
    groupName: null,
    groupSocialNetwork: null,

    //keywords
    keyword: {name: '', flag: 'OR'},
    keywords: [],

    //users posts
    userName: null,
    userProfile: {name: '', sid: 'FB'},
    userImages: [],

    //keywords in groups
    groups: [],*/

  formValues.taskName = apiTask.name;
  formValues.taskType = SupportService.webintTaskTypesReverse[apiTask.type];
  formValues.images = [];
  formValues.avatars = [];
  formValues.monitoring = apiTask.isMonitoring;
  formValues.monitoringStart = apiTask.schedulerSetting?.start;
  formValues.monitoringEnd = apiTask.schedulerSetting?.end;
  //formValues.collectPeriod = apiTask.schedulerSetting?.collectPeriod || 3;
  formValues.collectPeriod = apiTask.schedulerSetting?.collectPeriodInHour || config.webintMonitoringMinIntervalHrs;
  formValues.collectLikes = apiTask.collectLikes;
  formValues.collectComments = apiTask.collectComments;
  formValues.imageId = apiTask.imageId;
  formValues.keyword = {name: '', flag: 'OR'};
  formValues.userImages = [];
  formValues.socialNetwork = {sid: 'FB'};

  //assigned avatars
  if (apiTask?.avatarInfos && apiTask?.avatarInfos?.length) {
    apiTask?.avatarInfos.forEach(({id, profileName, socialNetwork, status}) => {
      formValues.avatars.push({
        id,
        name: profileName,
        sn: socialNetwork,
        status
      })
    })
  }

  ///groups
  formValues.groups = [];
  if (apiTask.type === 'GROUP_CHANNEL' && apiTask.groups) {
    const [saved] = apiTask.groups;
    if (saved) {
      formValues.groupName = saved.groupId;
      formValues.groupSocialNetwork = saved.socialNetwork;
    }
  }
  if (apiTask.type === 'GROUP_KEYWORDS') {
    apiTask.groups.forEach(({groupId, socialNetwork}) => {
      formValues.groups.push({sid: socialNetwork, name: groupId})
    });
  }

  ///keywords
  formValues.keywords = [];
  if (apiTask.type === 'KEYWORDS' || apiTask.type === 'GROUP_KEYWORDS') {
    formValues.keywords = apiTask.keywords.map(({name, keywordJoin}) => {
      return { name, flag: keywordJoin };
    })
  }

  ///posts
  if (apiTask.type === 'PROFILE_POSTS') {
    formValues.userName = apiTask.profile?.username;
    formValues.userProfile = {
      name: apiTask.profile?.socialProfileId,
      sid: apiTask.profile?.socialNetwork,
      imageId: apiTask.profile?.imageId,
    };
    if (apiTask.profile?.socialNetwork === 'MY_MAIL_RU') {
      if (apiTask.profile?.socialProfileId?.indexOf('/') > 0) {
        const [prefix, id] = apiTask.profile?.socialProfileId?.split('/');
        if (prefix && id) {
          formValues.userProfile = {
            ...formValues.userProfile,
            name: id,
            prefix: prefix
          };
        }
      }
    }

    if (apiTask.profile?.imageId) {
      formValues.userImageId = apiTask.profile?.imageId;
    }
  }

  console.log('prepared formValues', formValues);

  return formValues;
}

export const transformGroupActivity = apiData => {
  //console.log('groupActivity.data', apiData);
  const activityData = [];
  Object.keys(apiData).forEach((day) => {
    if (apiData[day]) {
      const fDay = moment(day).format("DD-MM-YYYY");
      const [series = {}] = apiData[day];
      const { shares = 0, comments = 0, likes = 0, posts = 0 } = series;
      activityData.push({ day: fDay, type: i18n.t("Shares"), value: shares });
      activityData.push({ day: fDay, type: i18n.t("Comments"), value: comments });
      activityData.push({ day: fDay, type: i18n.t("Likes"), value: likes });
      activityData.push({ day: fDay, type: i18n.t("Posts"), value: posts });
    }
  });
  const sortedData = [...activityData.sort(
    (a, b) => moment(a.day, "DD-MM-YYYY") - moment(b.day, "DD-MM-YYYY")
  )]
  console.log("transformGroupActivity", activityData, sortedData);

  return activityData;
}

export const transformGroupData = (apiGroups = []) => {
  return apiGroups.map(group => {
    if (group.socialNetwork === 'TELEGRAM') {
      if (group?.groupData && !group?.groupData?.url && group.groupId) {
        group.groupData.url = SupportService.getSocialProfileUrl('TELEGRAM', group.groupId);
      }
    }
    if (!group?.groupName && group?.groupId) {
      group.groupName = group.groupId;
    }
    return group;
  });
}

export const transformPostsData = (apiPosts = []) => {
  const performedPosts = [];
  apiPosts.forEach(post => {
    if (post.socialNetwork === 'TELEGRAM' && !post.postUrl) {
      //console.log('here!');
      if (post.postId && post.groupId) {
        post.postUrl = `${SupportService.getSocialProfileUrl('TELEGRAM', post.groupId)}/${post.postId}`;
      } else if (post?.messageId && post?.groupId) {
        post.postUrl = `${SupportService.getSocialProfileUrl('TELEGRAM', post.groupId + '')}/${post.messageId}`;
      }
      if (!post?.originalPostUserName && post?.groupId) {
        post.originalPostUserName = post?.groupId;
      }
    }
    if (post.socialNetwork === 'YOUTUBE') {
      if (post.originalPostUserName && post.originalPostUid) {
        const userName = post.originalPostUserName, userId = post.originalPostUid;
        post.originalPostUserName = userId;
        post.originalPostUid = userName;
        post.originalPostUserNameExtended = userName;
      }
      if (post?.totalReactionsCount == undefined && post?.likeCount) {
        post.totalReactionsCount = post.likeCount;
      }
    }
    if (!post.profileId && post.originalPostUid) {
      post.profileId = post.originalPostUid;
    }
    if (post['niceСount']) {
      post['niceCount'] = post['niceСount'];
    }
    if (post?.imageIds?.[0]) {
      post.originalPostUserImage = imageByIndex(post?.imageIds?.[0]);
    }

    performedPosts.push({
      ...post,
    });
  })
  return performedPosts;
}

export const transformPosts100Data = (apiPosts = []) => {
  const regularTransPosts = transformPostsData(apiPosts);
  const performedPosts = [];
  regularTransPosts.forEach(post => {
    if (post?.imageIds?.length) {
      const imageIds = [];
      post.imageIds.filter(e => e !== '-1').forEach(imgId => {
        imageIds.push(imageByIndex(imgId));
      })
      if (imageIds?.length > 0) {
        post.imagesUrl = imageIds;
      }
    }
    performedPosts.push({
      ...post,
    });
  })
  return performedPosts;
}

export const transformWebintUserStatData = (apiData = []) => {
  return apiData.map(info => {
    return {
      user: info.createdBy,
      task: <a href={`/webint/${info.id}`} target={targetLink()} rel={'noreferrer'}>{info.name}</a>,
      taskId: info.id,
      taskName: info.name,
      taskLink: `/webint/${info.id}`,
      monitoring: <Tag color={info.isMonitoring ? '#75b775' : '#de5959'}>{info.isMonitoring ? i18n.t('Yes') : i18n.t('No')}</Tag>,
      monitoringText: info.isMonitoring ? i18n.t('Yes') : i18n.t('No'),
      avatars: <div style={{textAlign: 'left',  width: '100%'}} key={info.id}>
        {
          info?.avatars?.map(({metaAvatarId, profileName, socialNetwork}, idx) =>
            <a href={`/amm/avatar/${metaAvatarId}`} target={targetLink()} rel={'noreferrer'}>
              <SocialIcon style={{justifyContent: 'flex-start', marginBottom: '5px'}} type={socialNetwork} text={profileName} />
            </a>
          )
        }
      </div>,
      avatarsArr: info.avatars,
      date: moment(info.createdDate).format("DD-MM-YYYY"),
    };
  });
}

export const prepareGroupPostStat = (postStats= {}) => {
  const days = {};
  const statsArray = [];
  for (const day in postStats) {
    statsArray.push({day: day, members: postStats[day]});
  }
  statsArray.sort((a, b) => a.day > b.day? 1 : -1)
  statsArray.forEach(({day, members}) => {
    const formatDay = moment(day).format("DD-MM-YYYY");
    days[formatDay] = members;
  })

  return Object.keys(days || {}).map(day => {
    return {
      day,
      members: days[day]
    }
  })
}