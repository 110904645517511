import { createGlobalStyle } from "styled-components";

import 'antd/dist/antd.css';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

export const GlobalStyle = createGlobalStyle`
  body {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    min-height: 100vh;
    min-width: 320px;
    margin: 0;
    padding: 0;
  }

  a:hover {
    opacity: .7;
  }
  
  a:hover,
  a:visited,
  a:focus {
      text-decoration: none !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #FFF !important;
  }
  p{
    margin: 0;
    padding: 0;
  }
  input:-internal-autofill-selected {
    background-image: none!important;
    background-color: inherit!important;    
  }
  input:-webkit-autofill{
    -webkit-text-fill-color: inherit !important;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  .custom-scroll::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #ADBACB;
    border-radius: 2px;
  }
  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
    padding: 0;
  }

  .grid-widget {
    /*border: 1px solid lightgray;*/
    /*border-radius: 5px;*/
    display: flex;
    flex-flow: column;
    border: 1px solid #A7B1BF;
  }

  .grid-widget-header {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    cursor: move;
  }

  .grid-widget-content {
    overflow: hidden;
    height: 100%;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }

  /*.page-enter {
    opacity: 0;
    transform: translate(0, 5px);
    z-index: 1;    
  }
  .page-enter.page-enter-active {
    opacity: 1;
    transform: translate(0, 0);

    transition: opacity 150ms ease-out, transform 300ms ease;    
  }
  .page-exit {
    opacity: 1;
    transform: translate(0, 0);    
  }
  .page-exit.page-exit-active {
    opacity: 0;
    transform: translate(0, 5px);

    transition: opacity 150ms ease-out, transform 100ms ease;    
  }*/

  
  
  //working
  
  .page-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  .page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 100ms, transform 100ms;
  }
  .page-exit {
    opacity: 1;
  }
  .page-exit-active {
    opacity: 0;
    display: none;
    transform: scale(0.95);
    transition: opacity 100ms, transform 100ms;
  }



  .disabled-transition-enter .ant-form-item-control {
    opacity: 0.4;    
  }
  .disabled-transition-enter-active .ant-form-item-control {
    opacity: 1;
    transition: opacity 500ms;
  }
  .disabled-transition-exit .ant-form-item-control {
    opacity: 1;
  }
  .disabled-transition-exit-active .ant-form-item-control {
    opacity: 0.4;
    transition: opacity 500ms;
  }
  .disabled-transition-exit-done .ant-form-item-control {
    opacity: 0.4;
  }
  /*THIS IS GOOD ONE*/
  /*.disabled-transition{
    display: flex;
  }
  .disabled-transition-enter .ant-form-item-control {
    opacity: 0;
    transform: scale(0.9);
  }
  .disabled-transition-enter-active .ant-form-item-control {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .disabled-transition-exit .ant-form-item-control {
    opacity: 1;
  }
  .disabled-transition-exit-active .ant-form-item-control {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
  }
  .disabled-transition-exit-done .ant-form-item-control {
     display: none;
   }*/





  /*  .fade-enter,
    .fade-exit {
      transition: transform 300ms ease-out;
    }
  
    .fade-enter {
      transform: translateX(100%);
    }
  
    .fade-enter.fade-enter-active {
      transform: translateX(0%);
    }
  
    .fade-exit {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateX(0%);
    }
  
    .fade-exit-active {
      transform: translateX(-100%);
    }*/

  /*.page {
    position: absolute;
    left: 15px;
    right: 15px;
  }

  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  .page-exit {
    opacity: 1;
    transform: scale(1);
  }

  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }*/

  .nlp-tooltip {
    position: relative;
    cursor: pointer;
  }
  .nlp-content {
    padding: 8px;
    color: #777;
    position: absolute;
    bottom: 90%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border: 1px solid #CFD0D7;

    margin-bottom: 5px;
    z-index: 100;    
  }
  .nlp-content h5{
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
  }


  .ant-popover-inner{
    padding-bottom: 9px!important;
  }


  .highlight-email{
    background-color: #f9f76d;
  }
  .highlight-phone{
    background-color: #92f986;
  }
  .highlight-keyword{
    background-color: #92f986;
  }
  
  //leaflet logo
  .leaflet-control-attribution{
    display: none;
  }
  .react-resizable-handle{
    z-index: 1000;    
  }
  
  //calendar
  .custom_calendar .ant-picker-calendar-mode-switch{
    display: none;
  }
  
  //timeline fixes
  .timeline-card-content{
    z-index: 99!important;
  }

  .regraph-tooltip {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: -18px;
    margin-left: 22px;

    width: 200px;
    padding: 6px 8px;
    background-color: #333;
    color: white;

    font-family: sans-serif;
    font-size: 14px;
    text-align: center;

    border-radius: 1.5px 3px 3px 1.5px;
    -webkit-box-shadow: -2px 2px 6px 0px rgba(94, 94, 94, 1);
    box-shadow: -2px 2px 6px 0px rgba(94, 94, 94, 1);

    pointer-events: none;
  }

  .regraph-tooltip-arrow {
    position: absolute;
    left: -11px;
    width: 22px;
    height: 22px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #333;
    z-index: 0;

    -webkit-box-shadow: -1px 4px 6px -2px rgba(94, 94, 94, 1);
    box-shadow: -1px 4px 6px -2px rgba(94, 94, 94, 1);
  }

  .regraph-tooltip-content {
    position: relative;
    background-color: #333;
    z-index: 1;
    /*height: 20px;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ant-picker-dropdown{
    z-index: 1080;
  }

`