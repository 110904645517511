import React, { useState, useEffect } from "react";
import SockJsClient from "react-stomp";
import config from "../../../config/config";
import {useDispatch, useSelector} from "react-redux";
import AlertsAction from "../../../store/alerts/alertsAction";
import {getUser} from "../../../store/auth/authSelector";
import usePermission from "../../../components/Permissions/usePermission";

const MainWebSocket = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const [isAdmin] = usePermission('role.admin');

  //state
  const [initSocket, setInitSocket] = useState(false);

  //effects
  useEffect(() => {
    setInitSocket(true);
  }, []);

  //handlers
  const handleWSMessage = msg => {
    if (currentUser) {
      if (isAdmin || currentUser.username === msg?.owner) {
        console.log('handleWSMessage', msg);
        dispatch(AlertsAction.incrementUnreadAlerts());
      }
    }

  }

  return (
    <>
      {initSocket && (
        <SockJsClient
          url={config.api_websocket}
          topics={['/topic/notifications']}
          onMessage={ msg => { handleWSMessage(msg) } }
          onConnect={() => {console.log('webint socket connected!')}}
          onDisconnect={() => {console.log('webint socket DISconnected!')}}
        />
      )}
    </>
  );
};

export default MainWebSocket;